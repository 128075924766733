import * as React from "react";
import logo from '../../images/logo.svg';

const Logo = () => {
    return (
        <section className="logo">
            <object type="image/svg+xml" data={logo} className="logo_image">
            </object>
        </section> 
    )
}
export default Logo;